(initGallery = () => {
    let logo = document.querySelector('.wo-opening > .wo-opening-logo');
    const galleryswiper = new Swiper('.swiper', {
        autoplay: {
            delay: 7000,
            disableOnInteraction: false,
            stopOnLastSlide: true
        },
        navigation: {
            prevEl: '.wo-swiper-button-prev',
            nextEl: '.wo-swiper-button-next'
        },
        pagination: {
            el: '.wo-swiper-pagination',
            clickable: false,
            type: 'bullets',
            bulletClass: 'wo-swiper-pagination-bullet',
            bulletActiveClass: 'wo-swiper-pagination-bullet-active'
        },
        loop: true,
        cssMode: false,
        simulateTouch: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        speed: 150,				
        slidesPerView: "auto",
        updateOnImagesReady: true,
        centeredSlides: true,
        on: {
            init: swiper => {
                swiper.pagination.destroy();
                setTimeout(() => {
                    swiper.pagination.init();
                    swiper.pagination.update();
                }, 0);

                loadIMG('picture source');
                loadIMG('img[loading]');
            },
            slideChange: swiper => {
                swiper.autoplay.stop();
                swiper.autoplay.start();

                const current = swiper.slides[swiper.activeIndex];
              
                // cambio colori in home
                let gallery = document.querySelector('.wo-opening');
                if (gallery) {
                    // sovrascrivo colori
                    if (current.dataset.color1)
                        gallery.style.setProperty('--color-foglia', current.dataset.color1);
                    if (current.dataset.color2)
                        gallery.style.setProperty('--color-foglia-2', current.dataset.color2);
                    
                    logo.classList.add('wo-opening-logo-fade-out');

                    setTimeout(() => {
                        const logo_pos = (Math.floor(Math.random() * 81) * -1) + "%";                                
                        logo.style.setProperty('--translate-logo', logo_pos);
                        logo.classList.remove('wo-opening-logo-fade-out');
                    }, 100);
                }
            },
            slideChangeTransitionEnd: swiper => {
            }
        }
    });
})();